// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-list-js": () => import("./../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-press-js": () => import("./../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-presslist-js": () => import("./../src/templates/presslist.js" /* webpackChunkName: "component---src-templates-presslist-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

